import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { actionGetRetailerCharacter } from '@lib/core/characters/slices';
import { useProducts } from '@lib/core/products/hooks/products';
import { actionResetCorrelatedProducts, getProductsByCharacterCorrelation } from '@lib/core/products/slices/products';
import { useQuizView, useUserQuiz } from '@lib/core/quizzes/hooks';
import { actionPatchUserQuizMetadata } from '@lib/core/quizzes/slices';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { useApp } from '@lib/core/service/hooks';
import { prependBasename } from '@lib/core/service/utils';
import { isProductSentia } from '@lib/tools/shared/pmi/products/filters';
import { recommendProducts } from '@lib/tools/shared/pmi/products/recommender';
import {
  isQuizAnswerTagDissatisfied,
  isQuizAnswerTagRecommendSentia,
  isQuizAnswerTagSatisfied,
  isResultLimitedCharacter,
} from '@lib/tools/shared/pmi/quizzes/filters';
import { HEETS, TEREA, VEEV } from '@lib/tools/shared/pmi/retailers/consts';
import { useAddons, useRetailerDesignSet } from '@lib/tools/views/hooks';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';
import { PAGES } from '@lib/tools/views/urls';

import Spinner from '@components/pmi/src/common/Spinner';
import QuizResultV2 from '@components/pmi/src/templates//Result/ResultPage';
import TasteAdvisorEcommerceResultLoading from '@components/pmi/src/templates/Ecommerce/ResultLoadingPage/ResultLoadingPage';
import QuizLoadingV2 from '@components/pmi/src/templates/LoadingPageV2/LoadingPageV2';
import { checkCurrentPrimaryColor, isOpenIntegration, isWyngEcommerce, navigateHome } from '@components/pmi/src/utils';

const QuizResult: FC = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const { productCategory } = useApp();
  const { retailerTags } = useRetailer();
  const { isRetailerLocationStoreTypeEcommerce } = useRetailerLocation();
  const {
    isAromaNoteSecondaryAddon,
    isAddToCartAddon,
    isAromaNotesDisabledAddon,
    isEssenceAromaDisabledAddon,
    isEssenceBodyDisabledAddon,
    isEssenceCoolingDisabledAddon,
    isEnableBuyEcommerceAddon,
    isEnableTereaBannerAddon,
    isEnableVeevBannerAddon,
    isEnableLeviaBannerAddon,
    isRestrictedProductCardsAddon,
    isRestrictedResultPageAddon,
    isTereaHideEssencesAddon,
    isTobaccoExperienceHiddenAddon,
    isProductLongDescriptionHiddenAddon,
    isProductShortDescriptionHiddenAddon,
    isHealthWarningFooterAddon,
    isMentholDisclaimerAddon,
    isHealthWarningFooterToIsraelAddon,
    isResultLongDisclaimerAddon,
    isRestrictedRecommendationAddon,
  } = useAddons();
  const { isDesignSetPmiV2 } = useRetailerDesignSet();
  const { userQuizId, userQuizCharacters } = useUserQuiz();
  const { quizViewAnswerTags } = useQuizView();
  const { productsByCharacterCorrelation, isProductsLoading } = useProducts();

  const currentPrimaryColor = checkCurrentPrimaryColor();

  const isResultLimitedCharacterCondition = quizViewAnswerTags.some(isResultLimitedCharacter);
  const isUserSatisfied = quizViewAnswerTags.some(isQuizAnswerTagSatisfied);
  const isUserDissatisfied = quizViewAnswerTags.some(isQuizAnswerTagDissatisfied);
  const isRecommendSentia = quizViewAnswerTags.some(isQuizAnswerTagRecommendSentia);
  const isSentiaProductAvailable = productsByCharacterCorrelation.some(isProductSentia);

  const tereaProducts = productsByCharacterCorrelation.filter(gprl => !isProductSentia(gprl));

  let technicalCaptionIcon = [];
  let technicalCaptionTextNumbers = [];
  if (isUserSatisfied) {
    technicalCaptionIcon = ['heart', 'thumb'];
    technicalCaptionTextNumbers = [2, 1];
  }
  if (isUserDissatisfied) {
    technicalCaptionIcon = ['thumb', 'heart'];
    technicalCaptionTextNumbers = [1, 2];
  }

  const isTechnicalCaptionEnable = (isUserSatisfied || isUserDissatisfied) && !isResultLimitedCharacterCondition;
  const isBackgroundEnabled = productCategory === TEREA;
  const isVeev = productCategory === VEEV;
  const isShowHealthWarningFooter =
    (!isRetailerLocationStoreTypeEcommerce || (isRetailerLocationStoreTypeEcommerce && isHealthWarningFooterAddon)) &&
    !isHealthWarningFooterToIsraelAddon;

  const userQuizCharacter = userQuizCharacters[0];
  const characterId = userQuizCharacter?.identifier;
  const characterHeaderCaption = userQuizCharacter?.character_caption;
  const characterHeaderDescription = userQuizCharacter?.character_description;
  const characterHeaderImage = userQuizCharacter?.image;
  const characterHeaderName = userQuizCharacter?.name;

  const sendProps = {
    characterHeaderCaption,
    characterHeaderDescription,
    characterHeaderImage,
    characterHeaderName,
    gprls: isSentiaProductAvailable && !isRecommendSentia ? tereaProducts : productsByCharacterCorrelation,
    isAddToCartAddon,
    isAromaNoteSecondaryAddon,
    isAromaNotesDisabledAddon,
    isBackgroundEnabled,
    isEnableBuyEcommerceAddon,
    isEnableLeviaBannerAddon,
    isEnableTereaBannerAddon,
    isEnableVeevBannerAddon,
    isEssenceAromaDisabledAddon,
    isEssenceBodyDisabledAddon,
    isEssenceCoolingDisabledAddon,
    isHealthWarningFooterToIsraelAddon,
    isMentholDisclaimerAddon,
    isOpenIntegration: isOpenIntegration(),
    isProductLongDescriptionHiddenAddon,
    isProductShortDescriptionHiddenAddon,
    isRestrictedProductCardsAddon,
    isRestrictedRecommendationAddon,
    isRestrictedResultPageAddon,
    isResultLongDisclaimerAddon,
    isSentiaEnabled: isRecommendSentia && isSentiaProductAvailable,
    isShowHealthWarningFooter,
    isTechnicalCaptionEnable,
    isTereaHideEssencesAddon,
    isTobaccoExperienceHiddenAddon,
    isVeev,
    maxQuantity: retailerTags?.ecommerceQuantityRange?.[1],
    minQuantity: retailerTags?.ecommerceQuantityRange?.[0],
    noCharacter: isResultLimitedCharacterCondition,
    recommendedGprls:
      isSentiaProductAvailable && !isRecommendSentia
        ? recommendProducts(tereaProducts)
        : recommendProducts(productsByCharacterCorrelation),
    resultPageBackgroundImage:
      'https://media-staging.taste-advisor-vh.com/media/product_attributes/v2backgrounds/v2_terea_quiz_background.png',
    selectedProductCategory: productCategory,
    sessionId: userQuizId,
    startOver: () => navigateHome(dispatch),
    technicalCaptionIcon,
    technicalCaptionTextNumbers,
  };

  useEffect(() => {
    if (characterId) {
      if (!productsByCharacterCorrelation.length) dispatch(getProductsByCharacterCorrelation({ characterId }));

      dispatch(actionGetRetailerCharacter({ characterId })).then(data => {
        if (!data) navigate(prependBasename(PAGES.error.notFound));
      });
    } else {
      navigate(prependBasename(PAGES.error.notFound));
    }

    return () => {
      dispatch(actionResetCorrelatedProducts());
    };
  }, [characterId]);

  useEffect(() => {
    if (productsByCharacterCorrelation.length) {
      const recommendedProducts = recommendProducts(productsByCharacterCorrelation);

      if (recommendedProducts) {
        const recommendedProductIds = recommendedProducts.map(gprl => gprl.product.identifier);

        const payload = {
          metadata: {
            result_recommended_products: recommendedProductIds,
          },
        };

        // Insert recommended products info into the userQuiz metadata.
        // Example:
        // {
        //   "metadata": {
        //     "result_recommended_products": ["IT001", "IT002", "IT003"]
        //   }
        // }
        dispatch(actionPatchUserQuizMetadata(payload));
      }
    }
  }, [productsByCharacterCorrelation]);

  if (
    ((isDesignSetPmiV2 && !isRetailerLocationStoreTypeEcommerce) ||
      (isRetailerLocationStoreTypeEcommerce && !isWyngEcommerce())) &&
    !isProductsLoading
  ) {
    return <QuizResultV2 {...sendProps} />;
  }

  if (
    isDesignSetPmiV2 &&
    !isRetailerLocationStoreTypeEcommerce &&
    (isProductsLoading || (productsByCharacterCorrelation.length && isWyngEcommerce()))
  ) {
    return <QuizLoadingV2 isHeetsBackgroundEnabled={productCategory === HEETS} />;
  }

  if (isRetailerLocationStoreTypeEcommerce && isProductsLoading) {
    return <TasteAdvisorEcommerceResultLoading currentPrimaryColor={currentPrimaryColor} />;
  }

  if (isProductsLoading || productsByCharacterCorrelation[0] === undefined) {
    return <Spinner />;
  }

  return <Spinner />;
};

export default QuizResult;
