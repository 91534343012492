import cn from 'classnames';
import { FC, MutableRefObject, useCallback, useLayoutEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ReactComponent as IconDown } from '@app/pmi/src/assets/media/V2/down-slate.svg';

import { isAppInIframe } from '@lib/core/service/utils';

interface Props {
  showScrollArrow?: boolean;
  isHealthWarningFooterToIsraelAddon?: boolean;
  bottomRef?: MutableRefObject<any>;
}

const ButtonScrollDown: FC<Props> = ({
  showScrollArrow = false,
  isHealthWarningFooterToIsraelAddon = false,
  bottomRef,
}) => {
  const [hideInfoIcon, setHideInfoIcon] = useState(isAppInIframe);
  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  const handleGoBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  const scrollHandler = useCallback(() => {
    // round to greater integer value to fix bug in Chrome where scrollY is not always an integer
    if (Math.ceil(window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      setHideInfoIcon(true);
    } else {
      setHideInfoIcon(false);
    }

    document.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  useLayoutEffect(() => {
    if (!isAppInIframe) scrollHandler();
  }, [isPortrait, window.innerHeight + window.scrollY, document.body.offsetHeight]);

  return (
    <>
      {showScrollArrow && !hideInfoIcon ? (
        <div className="ta-v2-btn-scroll-down">
          <button
            className={cn('ta-v2-btn-scroll-down-icon', {
              isHealthWarningFooterToIsraelAddon,
            })}
            onClick={handleGoBottom}
          >
            <IconDown />
          </button>
        </div>
      ) : null}
    </>
  );
};

export default ButtonScrollDown;
