import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import { store } from '@lib/core/service/store';
// eslint-disable-next-line import/order
import ServiceLauncher from '@lib/core/service/providers/ServiceLauncher';
import { isAppInIframe } from '@lib/core/service/utils';
import { Navigator } from '@lib/core/service/utils/Navigator';
import { DevTools } from '@lib/tools/devtools';
import LocaleProvider from '@lib/tools/locale/providers/LocaleProvider';

import Spinner from '@components/pmi/src/common/Spinner';

import CommsProvider from 'providers/CommsProvider';
import { DataProvider } from 'providers/DataProvider';
import Router from 'routes/RouterPmi';

// Styles
import 'rc-slider/assets/index.css';
import '@components/pmi/src/globals/_index.scss';

if (isAppInIframe) document.body.setAttribute('id', 'frame-body');

const View = () => {
  return (
    <LocaleProvider>
      <BrowserRouter>
        <Navigator />
        <DevTools />

        <Suspense fallback={<Spinner />}>
          <CommsProvider>
            <DataProvider>
              <Router />
            </DataProvider>
          </CommsProvider>
        </Suspense>
      </BrowserRouter>
    </LocaleProvider>
  );
};

const App = ServiceLauncher(View);
const persistor = persistStore(store);

createRoot(document.getElementById('root')!).render(
  <PersistGate loading={null} persistor={persistor}>
    <ReduxProvider store={store}>
      <App key="pmi" props={undefined} type={undefined} />
    </ReduxProvider>
  </PersistGate>,
);
